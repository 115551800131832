<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>Self Test</v-card-title>
        <v-card-title>
          <v-row no-gutter>
            <v-col cols="12">
              <v-form ref="form" v-model="el.validForm" lazy-validation>
                <div class="d-flex justify-space-between">
                  <v-select
                    class="mw-25 mr-5"
                    v-model="selfTest.question_number"
                    item-text="name"
                    item-value="value"
                    :items="questionNumbers"
                    label="Question Number"
                    outlined
                  />
                  <v-text-field
                    class="mw-25"
                    v-model="selfTest.order"
                    type="number"
                    label="Order Number"
                    outlined
                  />
                </div>
                <v-text-field
                  v-model="selfTest.body_nl"
                  type="text"
                  label="Question Text - NL"
                  :rules="[rule.required]"
                  outlined
                />
                <v-text-field
                  v-model="selfTest.body_en"
                  type="text"
                  label="Question Text - EN"
                  :rules="[rule.required]"
                  outlined
                />
                <v-divider></v-divider>
                <template v-if="selfTest.type === 'survey'">
                  <div class="mb-5">
                    <v-label>Sub Questions</v-label>
                  </div>
                  <div
                    v-for="child in selfTest.children"
                    :key="'child-' + child.id"
                  >
                    <v-text-field
                      class="mw-25 mr-5"
                      v-model="child.order"
                      type="number"
                      label="Order Number"
                      outlined
                    />
                    <v-text-field
                      v-model="child.body_nl"
                      type="text"
                      label="Question Text - NL"
                      outlined
                    />
                    <v-text-field
                      v-model="child.body_en"
                      type="text"
                      label="Question Text - EN"
                      outlined
                    />
                    <v-divider class="mb-5"></v-divider>
                  </div>
                </template>
                <div class="mb-5">
                  <v-label>Answers</v-label>
                </div>
                <div
                  class="d-flex justify-content-around"
                  v-for="answer in selfTest.answers"
                  :key="'answer-' + answer.id"
                >
                  <v-text-field
                    class="w-25 mr-5"
                    v-model="answer.body_nl"
                    type="text"
                    label="Answer Text - NL"
                    outlined
                  />
                  <v-text-field
                    class="w-25 mr-5"
                    v-model="answer.body_en"
                    type="text"
                    label="Answer Text - EN"
                    outlined
                  />
                  <v-text-field
                    class="w-25"
                    v-model="answer.score"
                    type="number"
                    label="Score"
                    outlined
                  />
                </div>
                <v-btn
                  color="primary"
                  class="float-right"
                  :loading="getSelfTestLoading"
                  @click="save"
                >
                  {{ isCreate ? `Create help search` : "Save changes" }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-title>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import rule from "@/shared/rules";
import _ from "lodash";
import store from "@/store";

export default {
  metaInfo: {
    title: "Self test"
  },
  data() {
    return {
      rule,
      id: _.get(this.$route, "params.id"),
      questionTypes: ["horizontal", "vertical", "survey"],
      questionNumbers: [
        {
          name: "empty",
          value: 0
        },
        ...[...Array(17).keys()].map(v => ({
          name: v + 1,
          value: v + 1
        }))
      ],
      selfTest: {
        question_number: null,
        body_nl: null,
        body_en: null,
        type: null,
        order: null,
        answers: []
      },
      el: {
        validForm: false,
        relationshipsLoading: false
      }
    };
  },
  async mounted() {
    console.log("DEBUG: questionNumbers", this.questionNumbers);
    if (!this.isCreate) {
      this.selfTest = _.cloneDeep(store.getters.getSelfTest(this.id));
    }
  },
  methods: {
    ...mapActions(["updateSelfTest"]),
    save() {
      if (!this.$refs.form.validate()) {
        store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      this.isCreate
        ? this.createSelfTest(this.selfTest)
        : this.updateSelfTest({ id: this.id, payload: this.selfTest });
    }
  },
  computed: {
    ...mapGetters(["getSelfTestLoading"]),
    isCreate() {
      return _.get(this.$route, "meta.kind") === "create";
    }
  }
};
</script>
<style lang="scss" scoped>
.w-25 {
  width: 25% !important;
}
.mw-25 {
  max-width: 25% !important;
}
</style>
